export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Page not found',
  subheader: 'Sorry, the page you were looking for could not be found.',
  buttonText: 'Return to homepage',
}
